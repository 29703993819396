<template>
  <div class="detailPage">
    <div class="detailContent">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
          <el-form-item label="疾病名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="疾病名称"></el-input>
          </el-form-item>
          <el-form-item label="疾病图标" prop="imgUrl">
			<el-upload
				action=""
				class="avatar-uploader"
				:show-file-list="false"
				accept="image/jpeg,image/png"
				:on-change="onUploadChange"
				:before-upload="beforeAvatarUpload"
				:auto-upload="false"
			>
				<img v-if="dataForm.imgUrl" :src="dataForm.imgUrl" class="avatar">
				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
          </el-form-item>
          <el-form-item label="简介" prop="description">
            <el-input v-model="dataForm.description" type="textarea" placeholder="简介"></el-input>
          </el-form-item>
          <el-form-item label="权重" prop="weight">
            <el-input v-model="dataForm.weight" placeholder="权重"></el-input>
          </el-form-item>
          <el-form-item class="buttons">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
import OSS from 'ali-oss';
import md5 from 'md5';

export default {
	name: 'diseaseOption',
	data () {
		return {
			dataForm: {
				id: 0,
				name: '',
				imgUrl: '',
				description: '',
				weight: 0
			},
			dataRule: {
				name: [
					{ required: true, message: '疾病名称不能为空', trigger: 'blur' }
				]
			},
			uploadUrl: process.env.VUE_APP_BASE_URL + '/mnt/upload-file',
			uploadHeaders: {}
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改疾病';
		} else {
			this.$route.meta.navName = '新增疾病';
		}
	},
	created () {
		const id = this.$route.query.id;
		if (id) {
			this.dataForm.id = id;
			this.getDetail(id);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		getDetail (id) {
			this.$root.request('diseaseDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						name: data.name,
						imgUrl: data.imgUrl,
						description: data.description,
						weight: data.weight || 0
					};
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					this.$root.request('diseaseAddOrUpdate', this.dataForm).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		},
		beforeAvatarUpload (file) {
			const isLt2M = file.size / 1024 < 10;
			if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10k!');
			}
			return isLt2M;
		},
		getTokenAndUpload (file, cb) {
			this.$root.request('getOSSToken', {
				page: 1,
				limit: 1000
			}).then((data) => {
				file.status = 'uploading';
				const imgType = file.raw.type.split('/')[1];
				const filename = file.raw.name + file.raw.size; // md5对图片名称进行加密
				const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
				const client = new OSS({
					region: data.region,
					// secure: true,
					stsToken: data.SecurityToken,
					accessKeyId: data.AccessKeyId,
					accessKeySecret: data.AccessKeySecret,
					bucket: data.bucket
				});
				client
					.put('odat/mnt/icons/' + keyValue, file.raw)
					// .multipartUpload(keyValue, file.file)
					.then((res) => {
						if (res.url) {
							file.url = res.url;
							file.status = 'uploaded';
							file.message = '';
							cb(res);
						} else {
							file.status = 'failed';
							this.$root.elMsg('图片上传失败', 'error');
							cb();
						}
					})
					.catch((err) => {
						console.log('message', err.message);
						file.status = 'failed';
						this.$root.elMsg('图片上传失败', 'error');
						cb();
					});
			});
		},
		onUploadChange (res, file) {
			this.getTokenAndUpload(res, () => {
				this.dataForm.imgUrl = res.url;
			});
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input,  .el-textarea {
		width: auto !important;
	}
	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 78px;
		text-align: center;
	}
	.avatar {
		width: 78px;
		height: 78px;
		display: block;
	}
</style>