var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "100px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "疾病名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "疾病名称" },
                  model: {
                    value: _vm.dataForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "name", $$v)
                    },
                    expression: "dataForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "疾病图标", prop: "imgUrl" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "",
                      "show-file-list": false,
                      accept: "image/jpeg,image/png",
                      "on-change": _vm.onUploadChange,
                      "before-upload": _vm.beforeAvatarUpload,
                      "auto-upload": false
                    }
                  },
                  [
                    _vm.dataForm.imgUrl
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.dataForm.imgUrl }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "简介", prop: "description" } },
              [
                _c("el-input", {
                  attrs: { type: "textarea", placeholder: "简介" },
                  model: {
                    value: _vm.dataForm.description,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "description", $$v)
                    },
                    expression: "dataForm.description"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "权重", prop: "weight" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "权重" },
                  model: {
                    value: _vm.dataForm.weight,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "weight", $$v)
                    },
                    expression: "dataForm.weight"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.dataFormSubmit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }